import React from "react";
import NavbarDrawer from "./NavbarDrawer";
function Header() {
  return (
    <header className="bg-gray-50 flex justify-between items-center px-4 sm:px-10 shadow-md py-4">
      <div className="flex justify-center items-center">
        <img
          src={"./assets/logo.png"}
          alt=""
          style={{
            width: "100px",
            aspectRatio: "auto 200 / 95",
            height: "100%",
          }}
        />
        <p className=" text-[#0B07C8] text-sm sm:text-lg">
          <p className="font-normal ">Somali Association for</p>
          <p className="font-light text-[#14A316]">Aid and Development</p>
        </p>
      </div>
      <ul className="xl:flex justify-between items-center text-sm  gap-10 cursor-pointer hidden">
        <li className="hover:text-[#14A316] transition-shadow">Home</li>

        {/* <li className="hover:text-[#14A316] transition-shadow mr-20">Programs And Events</li> */}
        <div className="dropdown dropdown-hover">
          <div tabIndex={0} role="button" className="transition-shadow">
            <span className="pr-3">About SAAD</span>
            <span>
              <i class="fa-solid fa-chevron-down"></i>
            </span>
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content z-[10] menu p-2 shadow bg-base-100 rounded-lg w-72"
          >
            <li>
              <a>Shelter</a>
            </li>
            <li>
              <a>Food</a>
            </li>
          </ul>
        </div>
        <div className="dropdown dropdown-hover">
          <div tabIndex={0} role="button" className="transition-shadow">
            <span className="pr-3">Where We Work</span>
            <span>
              <i class="fa-solid fa-chevron-down"></i>
            </span>
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content z-[10] menu p-2 shadow bg-base-100 rounded-lg w-72"
          >
            <li>
              <a>Shelter</a>
            </li>
            <li>
              <a>Food</a>
            </li>
          </ul>
        </div>
        <div className="dropdown dropdown-hover">
          <div tabIndex={0} role="button" className="transition-shadow">
            <span className="pr-3">Programs And Events</span>
            <span>
              <i class="fa-solid fa-chevron-down"></i>
            </span>
          </div>
          <ul
            tabIndex={0}
            className="dropdown-content z-[10] menu p-2 shadow bg-base-100 rounded-lg w-72"
          >
            <li>
              <a>Shelter</a>
            </li>
            <li>
              <a>Food</a>
            </li>
          </ul>
        </div>
        <div>
          <button className="bg-[#14A316] px-6 py-2 rounded-lg text-white">
            Contact Us
          </button>
        </div>
      </ul>
        <div>
          <button className="">
            <NavbarDrawer />
          </button>
        </div>
      {/* <NavbarDrawer /> */}
    </header>
  );
}

export default Header;
