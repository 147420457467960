import React from "react";

function Footer() {
  return (
    <div className="w-full h-auto lg:h-[500px] bg-black text-white flex flex-col gap-10 sm:flex-row justify-between p-10 sm:p-28 flex-wrap">
      <div className="w-[300px] sm:w-[450px] ">
        <p className=" text-[#0B07C8] pb-6">
          <p className="font-normal text-2xl">Somali Association for</p>
          <p className="font-light text-[#14A316]">Aid and Development</p>
        </p>
        <p className="text-justify leading-8">
          SAAD is humanitarian organization prepared to deliver emergency respond in
          man-made and natural disasters like drought and floods in the regions
          that may engulf the human, animal and farms in the regions of the
          country through distribution of emergency food aid, water, medicine
          and shelter for the effected families that need for help.
        </p>
      </div>

      {/* Quick Links */}

      <div className="">
        <h2 className="pb-6 text-3xl font-semibold">Quick Links</h2>
        <ul className="font-light text-lg flex flex-col gap-6">
          <li className="cursor-pointer">About SAAD</li>
          <li className="cursor-pointer">Programs And Events</li>
          <li className="cursor-pointer">Where We Work</li>
          <li className="cursor-pointer">Gallery</li>
          <li className="cursor-pointer">Become A Volunteer</li>
          <li className="cursor-pointer">Contact Us</li>
        </ul>
      </div>

      {/* CONTACT DETAILS */}

      <div className="">
        <h2 className="pb-6 text-3xl font-semibold">Contact Details</h2>
        <ul className="font-light  flex flex-col gap-6">
          <li className="flex gap-5 cursor-pointer items-center">
            <p>
              <i class="fa-regular fa-envelope text-2xl"></i>
            </p>
            <p>saadngo2@gmail.com</p>
          </li>
          <li className="flex gap-5 cursor-pointer items-center">
            <p>
              <i class="fa-regular fa-envelope text-2xl"></i>
            </p>
            <p>xasancilmi2015@sad.so</p>
          </li>
          <li className="flex gap-5 cursor-pointer items-center">
            <p>
              <i class="fa-solid fa-phone text-2xl"></i>
            </p>
            <p>+252-615241798</p>
          </li>
          <li className="flex gap-5 cursor-pointer items-center">
            <p>
              <i class="fa-solid fa-phone text-2xl"></i>
            </p>
            <p>+252-612935000</p>
          </li>
          <li className="flex gap-5 cursor-pointer items-center">
            <p>
              <i class="fa-solid fa-location-dot text-2xl"></i>
            </p>
            <p>
              Bal'ad Street Behani, Shibis District <br /> -Mogadishu-Somalia
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
