import React from "react";

function Community() {
  return (
    <div
      className="mb-28 w-full h-[500px] bg-[#0B07C8] text-white flex justify-center items-center"
      style={{
        backgroundImage: `linear-gradient(rgba(60, 137, 109, 0.7),rgba(157, 219, 173, 0.9)), url('./assets/community_1.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="p-6 w-full sm:w-[600px] flex justify-center items-center flex-col gap-10 ">
        <h1 className="text-4xl text-center font-semibold">
          Join Our Community
        </h1>
        <p className="font-light text-lg text-justify">
          Join us in establishing peace & development through promotion of
          peaceful conflict resolution mechanism and undertaking developmental
          programmes that aim to alleviate suffering and restoring human dignity
          and rights in Somalia.
        </p>
        <div>
          <button className="text-[#14A316] hover:text-white hover:anima text-xl px-6 py-2 rounded-lg underline underline-offset-2">
            Become a Volunteer
          </button>
        </div>
      </div>
    </div>
  );
}

export default Community;
