import React from "react";

function NavbarDrawer() {
  return (
    <div className="drawer block xl:hidden">
      <input id="my-drawer" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        {/* Page content here */}
        <label
          htmlFor="my-drawer"
          className="btn btn-[#010] text-2xl drawer-button"
        >
          <i class="fa-solid fa-bars-staggered"></i>
        </label>
      </div>
      <div className="drawer-side z-10">
        <label
          htmlFor="my-drawer"
          aria-label="close sidebar"
          className="drawer-overlay"
        ></label>

        <ul className="menu py-4 w-72 min-h-full text-base-content bg-white text-left">
          <div className="flex justify-center items-center">
            <img
              src={"./assets/logo.png"}
              alt=""
              style={{
                width: "95px",
                aspectRatio: "auto 200 / 95",
                height: "100%",
              }}
            />
            <p className=" text-[#0B07C8] text-sm sm:text-lg">
              <p className="font-normal ">Somali Association for</p>
              <p className="font-light text-[#14A316]">Aid and Development</p>
            </p>
          </div>
          <div className="divider divider-vertical"></div>
          {/* Sidebar content here */}
          <li className="pl-8">
            <a href="#demo">Home</a>
          </li>
          <li className="dropdown text-left">
            <div tabIndex={0} role="button" className="transition-shadow">
              <span>
                <i class="fa-solid fa-chevron-down pr-3"></i>
              </span>
              <span className="">About SAAD</span>
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-[10] menu p-2 shadow bg-base-100 rounded-lg w-72"
            >
              <li>
                <a>Shelter</a>
              </li>
              <li>
                <a>Food</a>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <div tabIndex={0} role="button" className="transition-shadow">
              <span>
                <i class="fa-solid fa-chevron-down pr-3"></i>
              </span>
              <span className="">Where We Work</span>
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-[10] menu p-2 shadow bg-base-100 rounded-lg w-72"
            >
              <li>
                <a>Shelter</a>
              </li>
              <li>
                <a>Food</a>
              </li>
            </ul>
          </li>
          <li className="dropdown">
            <div tabIndex={0} role="button" className="transition-shadow">
              <span>
                <i class="fa-solid fa-chevron-down pr-3"></i>
              </span>
              <span className="">Programs And Events</span>
            </div>
            <ul
              tabIndex={0}
              className="dropdown-content z-[10] menu p-2 shadow bg-base-100 rounded-lg w-72"
            >
              <li>
                <a>Shelter</a>
              </li>
              <li>
                <a>Food</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default NavbarDrawer;
