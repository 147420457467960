import React from "react";

function ProjectCard({event}) {
  return (
    <div
      className={` ${event.id===3?"w-[350px]":""} p-4 w-[350px] lg:w-[400px] h-[460px] bg-no-repeat bg-center bg-cover bg-green-gradient text-white rounded-lg flex flex-col justify-center gap-6  items-center pt-28 `}
      style={{
        backgroundImage: `linear-gradient(rgba(11, 7, 200, 0.6),rgba(11, 7, 200, 0.9)), url(${event.img})`,
      }}
    >
      <h2 className="text-2xl font-medium  text-center ">{event.title}</h2>
      <p className="font-light">
        {event.info}
      </p>
      <div>
          <button className="text-[#14A316] px-6 py-2 rounded-lg underline-offset-2 underline">Find Out More</button>
        </div>
    </div>
  );
}

export default ProjectCard;
