import React from "react";
import {
  A11y,
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
function Slider({ slides }) {
  if (!slides || slides.length === 0) {
    return <div>No slides available.</div>;
  }
  return (
    <Swiper
      modules={[Navigation, Scrollbar, Pagination, A11y, Autoplay]} // Add Autoplay here
      spaceBetween={50}
      slidesPerView={1}
      loop={true} // Enable looping
      autoplay={{
        delay: 2500,
      }}
      scrollbar={{ draggable: true }}
    >
      {slides.map((slide) => (
        <SwiperSlide key={slide.id}>
          <div
            className="w-full h-[600px] bg-no-repeat bg-center bg-cover bg-green-gradient text-white flex flex-col justify-center px-10 md:px-20  lg:px-64 gap-6"
            style={{
              backgroundImage: `linear-gradient(rgba(60, 137, 109, 0.7),rgba(157, 219, 173, 0.9)), url(${slide.imgURL})`,
            }}
          >
            {/* Content inside slide */}
            <h2 className="text-3xl xl:text-5xl font-semibold">Promoting Living Standards Of Communities In Somalia</h2>
            <p className="font-light xl:text-lg">
              SAAD is working in the country sides support and care through care
              provision of medical support, shelter, construction homes,
              sanitation, construction of toilets, livelihood programs for
              vocational training and income generation in Somalia.
            </p>
            <div>
          <button className="bg-[#14A316] px-6 py-2 rounded-lg text-white">Learn More.</button>
        </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Slider;
