import React from "react";

function GeographyArea() {
  return (
    <div className="pb-28 md:p-10 lg:p-28  flex justify-around items-center gap-10 flex-col-reverse sm:flex-row">
      <div
        className="h-[350px] lg:h-[500px] w-[350px] lg:w-[600px] rounded-lg drop-shadow-lg"
        style={{
          backgroundImage: `linear-gradient(rgba(60, 137, 109, 0.7),rgba(157, 219, 173, 0.9)), url('./assets/1.jpg')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        alt="Geography-Area" // Note: `alt` tag is not valid here and should be removed or handled differently
      >
        {/* Optional content can go here */}
      </div>
      <div className="w-[350px] lg:w-[600px]">
        <h1 className="text-3xl lg:text-4xl text-center font-semibold lg:p-10 p-4">
          Geographical Area
        </h1>
        <p className="font-light text-sm lg:text-lg text-justify">
          SAAD Somali Association for Aid and development is a local NGO based
          in Somalia established in 2015 by a group of intellectuals and
          scholars who realized the need and the problem in the country of
          Somalia where there is decades of unrest civil wars and wide bread
          poverty that doomed tens thousands of people mostly children and women
          who are the most vulnerable groups in the society.
        </p>
      </div>
    </div>
  );
}

export default GeographyArea;
