import React from 'react'
import Header from '../components/Header'
import Sliders from '../components/Sliders'
import ProjectCards from '../components/ProjectCards'
import GeographyArea from '../components/GeographyArea'
import Community from '../components/Community'
import Partners from '../components/Partners'
import Footer from '../components/Footer'

function Home() {
  return (
    <div className='bg-white'>
        <Header></Header>
        <Sliders />
        <ProjectCards/>
        <GeographyArea />
        <Community />
        <Partners />
        <Footer />
    </div>
  )
}

export default Home