import React from "react";
import ProjectCard from "./ProjectCard";

function ProjectCards() {
  const eventsData = [
    {
        id:'1',
      title: "SCHOOLS",
      info: "is humanitarian organization prepared to deliver emergency respond in man-made and natural disasters like drought and floods in the regions that may engulf the human, animal and farms in the regions of the country through distribution of emergency food aid, water, medicine and shelter for the effected families that need for help.",
      img:'./assets/vertical/16.png'
    },
    {
        id:'2',
      title: "LAKES",
      info: "is humanitarian organization prepared to deliver emergency respond in man-made and natural disasters like drought and floods in the regions that may engulf the human, animal and farms in the regions of the country through distribution of emergency food aid, water, medicine and shelter for the effected families that need for help.",
      img:'./assets/vertical/6.png'
    
    },
    {
        id:3,
      title: "TREATMENTS",
      info: "is humanitarian organization prepared to deliver emergency respond in man-made and natural disasters like drought and floods in the regions that may engulf the human, animal and farms in the regions of the country through distribution of emergency food aid, water, medicine and shelter for the effected families that need for help.",
      img:'./assets/vertical/23.png'
    },
  ];
  return <div className="py-28 ">
    <h2 className="pb-20 text-4xl text-center font-semibold">Success Stories/Projects</h2>
    <div className="flex justify-center items-center gap-10 flex-col sm:flex-row flex-wrap">
    {
        eventsData.map(event=>(
            <ProjectCard key={event.id} event={event}/>
        ))
    }
  </div>
  </div>;
}

export default ProjectCards;
