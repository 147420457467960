import React from "react";
import {
  A11y,
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";
function PartnerSlide({ partners }) {
  if (!partners || partners.length === 0) {
    return <div>No partners available.</div>;
  }
  return (
    <Swiper
      modules={[Navigation, Scrollbar, Pagination, A11y, Autoplay]} // Add Autoplay here
      spaceBetween={50}
      slidesPerView={4}
      loop={true} // Enable looping
      autoplay={{
        delay: 500,
      }}
    >
      {partners.map((partner) => (
        <SwiperSlide key={partner.id}>
          <img src={partner.img} className="w-[100px] h-[80px]" alt="" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default PartnerSlide;
