import React from 'react'
import PartnerSlide from './PartnerSlide'

function Partners() {
    const partners = [
        {
            id:1,
            img:'./assets/partners/un.jpeg'
        },
        {
            id:2,
            img:'./assets/partners/british Aid.png'
        },

        {
            id:3,
            img:'./assets/partners/unicef.jpeg'
        },
        {
            id:4,
            img:'./assets/partners/European.jpeg'
        },
        {
            id:5,
            img:'./assets/partners/norwegian refugee.png'
        },
        {
            id:6,
            img:'./assets/partners/somalia.png'
        },
    ]
  return (
    <div className='pb-28'>
        <h1 className="pb-20 text-4xl text-center font-semibold">Our Partners</h1>
        <div className='w-full lg:w-[1200px] mx-auto'>
        <PartnerSlide partners={partners} />
        </div>
    </div>
  )
}

export default Partners